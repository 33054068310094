.curriculum-outer-container {
    width: 100vw;
    background: var(--primary-bg);
    padding-bottom: 100px;
    color: var(--primary-color);
}

.curriculum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    padding-top: 100px;
}


.curriculum-container p {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center;
}

.curriculum-container .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 1rem; */
    background: var(--secondary-bg);
    width: 50%;
    margin: auto;
    flex-wrap: wrap;
    gap: 2rem;
    padding-top: 30px;
    padding-bottom: 30px;
    min-width: 400px;
    /* border-radius: .5rem; */
    /* padding-left: 20px;
    padding-right: 20px; */
}

.curriculum-container .bottom .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
    /* max-width: 400px; */
    background: var(--primary-bg);
    width: 90%;
    /* width: 200px; */
    /* height: 150px; */
    border-radius: 0px;
    gap: 1rem;
    padding: 2rem;
    /* transition: all 0.3s ease; */
}
  
.card {
    max-width: 100%;
}

.card:hover {
    transform: none;
}

.curriculum-container .top {
    font-weight: 700 !important;
    padding: 10px;
}

.module-heading {
    /* font-weight: 700 !important; */
    font-size: 20px !important;
    line-height: 30px !important;
}

.down_arrow img,.inner_down_arrow img{
    max-width: 22px;
    max-height: 22px;
    /* float: right; */
}

.module-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
}

.sub-module-container {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 24px !important;
}

.sub-topic-container {
    display: none;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    cursor: auto;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-transform: uppercase;
}

.sub-module-container.active {
    color: blue; 
}

