.super-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--secondary-bg);
  padding: 2rem 0;
  /* display: none; */
}
.footer-container {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 2rem; */
  justify-content: space-around;
  margin: 0 2rem;
  width: 100%;
  overflow-x: hidden !important;
}
.footer-container .first {
  width: 36%;
  /* background-color: rgb(215, 201, 201); */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 28px;
  font-size: 1.1rem;
  color: var(--primary-color)
}
.footer-container .first .bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer-container .second {
  width: 30%;
  /* background-color: rgb(217, 217, 233); */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  line-height: 40px;
  margin-top: 2rem;
  font-weight: 500;
}
.footer-container a {
  color: var(--primary-color);
  transition: all 0.3s ease;
}
.footer-container .footer-nav:hover {
  background: -webkit-linear-gradient(90deg, #f77b26 0%, #f6673a 100%);
  color: var(--secondary-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.footer-container .third {
  width: 30%;
  /* background-color: rgb(198, 202, 198); */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  color: var(--primary-color)
}
.footer-container .third .top {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.footer-container .third p {
  font-size: 1.1rem;
  font-weight: 600;
}
.footer-container .third .top .social-icons {
  display: flex;
  gap: 1rem;
}
.footer-container .third .top .social-icons a {
  font-size: 1.6rem;
  font-weight: 600;
}
.footer-container .third .bottom {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-container img {
  width: 300px;
  height: 100px;
}

/* media query (small laptop devices) */
@media screen and (max-width: 1300px) {
  .footer-container .first {
    width: 46%;
  }
  .footer-container .second {
    width: 25%;
  }
  .footer-container .third {
    width: 25%;
  }
}

/* media query (medium devices) */
@media screen and (max-width: 900px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden !important; */
    margin: 0;
  }
  .footer-container .first {
    width: 90%;
    margin: auto;
    margin: 0;
  }
  .footer-container .second {
    width: 90%;
    margin: auto;
    margin-top: 0;
    align-items: flex-start;
    margin: 0;
  }
  .footer-container .third {
    width: 90%;
    margin: auto;
    margin-top: 0;
    align-items: flex-start;
    margin: 0;
  }
  .footer-container img {
    width: 250px;
    /* height: 100px; */
  }
}
